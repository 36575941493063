export default function numberFormatter(
  amount: number,
  digits = 0,
  cutZeros = false,
  percentage = false,
) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
  const formatedNumber = formatter.format(amount);

  if (cutZeros) {
    const withoutZeros = formatedNumber.split("");
    const numberLength = withoutZeros.length;

    for (let i = 1; i <= digits + 1; i++) {
      const lastDigit = withoutZeros[numberLength - i];
      if (lastDigit === "0" || lastDigit === ".") {
        withoutZeros.splice(numberLength - i, 1);
      } else {
        break;
      }
    }

    if (percentage) {
      withoutZeros.push("%");
    }

    return withoutZeros.join("");
  }

  if (percentage) {
    return `${formatedNumber}%`;
  }

  return formatedNumber;
}
